export default {
  heading: 'PT Serif, serif',
  body: 'Yanone Kaffeesatz, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Saira Extra Condensed',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Yanone Kaffeesatz:200,300,400,500,600,700,800,900',
    'PT Serif:100,200,300,400,500,600,700,800,900',
    'Saira Extra Condensed:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: ['Din'],
  // customUrls: ['https://www.gonation.biz/fonts/din/din.css'],
}

export default {
  primary: {
    color: 'light',
    border: 'solid 1px',
    fontSize: '0.7rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '1rem 3rem',
    borderColor: 'light',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'dark',
      color: 'light'
    }
  },
  secondary: {
    backgroundColor: 'secondary',
    fontFamily: 'heading',
    padding: '1rem 2rem',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '0.85rem',
    transition: 'all ease-in-out 0.8s',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'secondary'
    }
  }
}
